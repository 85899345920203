import {createSlice} from '@reduxjs/toolkit'
import BlowFish from 'egoroof-blowfish'
import {Base64} from 'js-base64'


class EncryptBlowfish {
    constructor(textToEncode,textToDecode) {
      this.textEncode = textToEncode;
      this.textDecode = textToDecode;
      this.bf = new BlowFish('prmd2024', BlowFish.MODE.ECB, BlowFish.PADDING.NULL)
      this.bf.setIv('esharp24');
    }
    encrypt(){
        const encoded = this.bf.encode(JSON.stringify(this.textEncode));
        return encoded;
    }
    decrypt = ()=>{
        const decoded =JSON.parse( this.bf.decode(this.textDecode, BlowFish.TYPE.STRING))
        return decoded;
    }
  }


const INITIAL_STATE = {
    data:localStorage.getItem("data") == null? null:
    JSON.parse(new EncryptBlowfish("",Base64.toUint8Array(localStorage.getItem("data"))).decrypt())
};

const sessionSlice = createSlice(
    {
        name:'session',
        initialState:INITIAL_STATE,
        reducers:{
            addSession:(state,action)=>{
                const encryptBlowfishs = new EncryptBlowfish(JSON.stringify(action.payload.data),"").encrypt();
                const encryptBase64 = Base64.fromUint8Array(encryptBlowfishs, true);
                localStorage.setItem('data',encryptBase64);
                state.data =JSON.parse(new EncryptBlowfish("",Base64.toUint8Array(localStorage.getItem("data"))).decrypt())  
                return state;
            },
            logout:(state,action)=>{
                localStorage.removeItem('data');
                state.data = INITIAL_STATE;
                return state;
            },
            updateToken:(state,action)=>{
                state.data.accessToken = action.payload.accessToken;
                const encryptBlowfishs = new EncryptBlowfish(JSON.stringify(state.data),"").encrypt();
                const encryptBase64 = Base64.fromUint8Array(encryptBlowfishs, true);
                localStorage.setItem('data',encryptBase64);
                state.data =JSON.parse(new EncryptBlowfish("",Base64.toUint8Array(localStorage.getItem("data"))).decrypt())
                return state;
            }
        }
    }
);

export const sessionAction = sessionSlice.actions;
export const sessionSelector = (state) => state.session.data;
export default sessionSlice